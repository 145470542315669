// in src/clientes.js
import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    TextInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Show,
    SimpleShowLayout,
    ShowButton,
    FunctionField,
    ArrayField,
    useRedirect,
    useRecordContext,
    useRefresh
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {StringToLabelObject} from "./App";
import Button from "@mui/material/Button";

import {LoadData} from "./Utils/Services/Edit_services";

const ClientesFilter = [
    <TextInput label="Buscar..." source="q" alwaysOn />,
];

const urlAPI = `${process.env.REACT_APP_API_URL}`;

function replicarTiposCliente(id)
{

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    fetch(`${urlAPI}replicar/tipos/leads/cliente/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

}

const DashboardButton = (props) => {

    const record = useRecordContext();
    const redirect = useRedirect();
    const handleClick = () => {
        redirect('/categoriasLicencias/'+record.id);
    };

    let nameButton = (record.new) ? 'Crear' : 'Editar';

    return <Button className="classes.btn_create" onClick={handleClick} {...props} >{nameButton}</Button>;
};

const DeletedButton = (props) => {

    const refresh = useRefresh();
    const record = useRecordContext();
    const handleClick = async () => {

        let response = await fetch(`${urlAPI}lt/categoriasLicencias/${record.id}`, {method: 'DELETE'})
            .then(response => response.json());

        if (response) {
            refresh();
        }

    };

    return <Button className="classes.btn_deleted" onClick={handleClick} {...props} >Eliminar</Button>;
};


const ServicesButton = (props) => {

    const refresh = useRefresh();
    const record = useRecordContext();
    const handleClick = async (data) => {

       /* let response = await fetch(`${urlAPI}lt/categoriasLicencias/${record.id}`, {method: 'DELETE'})
            .then(response => response.json());

        if (response) {
            refresh();
        }*/

    };

    return <Button className="classes.btn_deleted" onClick={handleClick} {...props} >Actualizar servicio</Button>;
};


export const ClientesShow = () => (
    <Show>
        <SimpleShowLayout>

            <h1>Datos del cliente</h1>
            <TextField source="nombre" />
            <TextField source="api_token" />
            <BooleanField source='activo' />
            <ReferenceArrayField label='Concesionarios' reference='concesionarios' source='concesionarios' link='show'>
            <SingleFieldList linkType="show">
                <ChipField source='nombre' />
            </SingleFieldList>
            </ReferenceArrayField>
            <FunctionField render={(record) => (
                <Button label='Replicar tipos de leads' onClick={() => replicarTiposCliente(record.id)}>Replicar tipos de leads</Button>
            )} />

            <LoadData typeData="client"></LoadData>

            <h1>Listado de estados</h1>
            <ArrayField label="Estados - Licencias" source="states">
                <Datagrid>

                    <TextField source="id" />

                    <TextField source="state.name" />

                    <ArrayField source="roles">
                        <SingleFieldList>
                            <StringToLabelObject>
                                <ChipField source="label" />
                            </StringToLabelObject>
                        </SingleFieldList>
                    </ArrayField>

                    <DashboardButton ></DashboardButton>

                    <DeletedButton></DeletedButton>

                </Datagrid>
            </ArrayField>


        </SimpleShowLayout>
    </Show>
);

export const ClientesList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List {...props} filters={ClientesFilter} >
        {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
        ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="nombre" />
                    <BooleanField source="activo" />
                    <ReferenceArrayField label='Concesionarios' reference='concesionarios' source='concesionarios'>
                        <SingleFieldList linkType="show">
                            {/* <ChipField source='nombre' /> */}
                            <FunctionField render={(record) => (
                                <ChipField source="nombre"
                                variant='outlined'
                                color={record.activo ? 'success':'error'}
                                icon={record.activo ? <DoneIcon />:<ClearIcon /> } />
                            )} />
                        </SingleFieldList>
                    </ReferenceArrayField>

                    <FunctionField render={(record) => (
                        <Button label='' onClick={() => replicarTiposCliente(record.id)}>Replicar tipos</Button>
                    )} />

                    <ShowButton />
                </Datagrid>
        )}
    </List>
    );
};